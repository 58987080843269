type Settings = {
  DOMAIN: string;
  API_DOMAIN: string;
  CDN_DOMAIN: string;
  DEEPLINK_URL: string;
  SCAN_DEEPLINK_URL: string;
  MODE?: string;
  GA_ID?: string;
};

const ENV_MODE = process.env.NEXT_PUBLIC_MODE;
const isLocal =
  ENV_MODE === "development" && process.env.NEXT_PUBLIC_LOCAL_MODE === "true";
const GA_ID = process.env.NEXT_PUBLIC_GA_ID;

const devDefaultSettings: Settings = {
  DOMAIN: "https://godev.elecle.me",
  API_DOMAIN: "https://api.elecle.me",
  CDN_DOMAIN: "https://d12plrb6tqz2pw.cloudfront.net/assets",
  DEEPLINK_URL: "https://pyces.test-app.link/kmxTyR43LGb",
  SCAN_DEEPLINK_URL: "https://pyces.test-app.link/KlqmvzUNtMb",
  MODE: "development",
  GA_ID
};

const prodDefaultSettings: Settings = {
  DOMAIN: "https://go.elecle.bike",
  API_DOMAIN: "https://api.elecle.bike",
  CDN_DOMAIN: "https://cdn.elecle.bike/assets",
  DEEPLINK_URL: "https://matrix.elecle.bike/VYQa1J4u4Ib",
  SCAN_DEEPLINK_URL: "https://matrix.elecle.bike/JljkFlkCtMb",
  MODE: "production",
  GA_ID
};

const environmentSettings: { [key: string]: Settings } = {
  development: {
    ...devDefaultSettings,
    MODE: "development"
  },
  localDevelopment: {
    ...devDefaultSettings,
    MODE: "local",
    DOMAIN: "http://localhost:9292"
  },
  production: {
    ...prodDefaultSettings,
    MODE: "production"
  }
};

export const settings: Partial<Settings> = {
  ...(ENV_MODE === "production" && environmentSettings.production),
  ...(ENV_MODE === "development" && environmentSettings.development),
  ...(isLocal && environmentSettings.localDevelopment)
};

export const kako_settings = {
  KAKAO_JAVASCRIPT_KEY:
    ENV_MODE === "production"
      ? "ccc2f0aa315e47f28a3d4323030acc94"
      : "d25e32c594ae08377dcda20413a2fc4c",
  KAKAO_REDIRECT_URI_PATH: "/oauth/kakao"
};
